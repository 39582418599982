import React from "react";
import { DataGrid } from "@material-ui/data-grid";

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 90,
    sortable: false,
    editable: false,
    hide: true,
  },
  {
    field: "file_id",
    headerName: "file id",
    sortable: false,
    editable: false,
    width: 300,
  },
  {
    field: "file_name",
    headerName: "file name",
    sortable: false,
    editable: false,
    width: 200,
  },
  {
    field: "length",
    headerName: "length",
    sortable: false,
    editable: false,
    width: 110,
    // type: "number",
  },
  {
    field: "uploadDate",
    headerName: "uploadDate",
    sortable: false,
    editable: false,
    width: 180,
  },
  {
    field: "chunkSize",
    headerName: "chunkSize",
    sortable: false,
    editable: false,
    width: 120,
  },
];

const FileListTable = ({ fileList }) => {
  // React.useEffect(() => {
  //   console.log("fileList: ", fileList);
  // }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <DataGrid 
          rows={fileList} 
          columns={columns} 
          autoHeight 
          disableSelectionOnClick 
        />
      </div>
    </>
  );
};

export default FileListTable;
