import React, { useEffect, useState } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import FileListTable from "./Components/FileListTable";
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Collapse from '@material-ui/core/Collapse';
import { cyan, blue } from "@material-ui/core/colors";

const useCardStyle = makeStyles(() => ({
  CardTitle: {
    padding: 0,
    marginTop: 0,
    marginBottom: 30,
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: cyan["100"],
      main: cyan["600"],
      dark: cyan["900"],
      contrastText: '#fff',
    },
    secondary: {
      light: blue["100"],
      main: blue["700"],
      dark: blue["900"],
      contrastText: '#000',
    },
  },
});

const FileListMain = () => {
  const cardClasses = useCardStyle();

  const [fileList, setFileList] = useState([]);
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  useEffect(() => {
    getFileList();
  }, []);

  const getDate = (dateData) => {
    try {
      // let tmp = "Wed, 03 Aug 2022 06:04:15 GMT";
      let date = new Date(dateData);
      let offset = date.getTimezoneOffset() * 60000;
      let dateOffset = new Date(date.getTime() - offset);
      let dateOffsetISO = dateOffset.toISOString();
      let result = dateOffsetISO.replace("T", " ").split(".")[0];
      return result;
    } catch (error) {
      console.log("error: ", error);
    };
  };

  const getFileList = () => {
    axios({
      url: "/rest/1.0/list/files",
    })
      .then((res) => {
        let dataTmp = res.data;
        if (dataTmp.length > 0) {
          setFileList(
            dataTmp.map((data, idx) => {
              let kstDate = getDate(data.uploadDate);
              return { ...data, id: idx, uploadDate: kstDate };
            })
          );
        } else {
          setCollapseOpen(true);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  return (
    <MuiThemeProvider theme={theme}>
      <Card>
        <CardBody>
          <h3 className={cardClasses.CardTitle}>LISTING</h3>
          <GridContainer>
            <GridItem xs={12}>
              {fileList.length > 0 ? (
                <FileListTable fileList={fileList} />
              ) : (
                <Collapse in={collapseOpen}>
                  <Alert severity="info">
                    <AlertTitle>Info</AlertTitle>
                    There is no data. — <strong>check it out!</strong>
                  </Alert>
                </Collapse>
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </MuiThemeProvider>
  );
};

export default FileListMain;
