import React from 'react';
import { 
  Grid, 
  Dialog, 
  DialogActions, 
  DialogContentText, 
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SearchingModelInfo from './SearchingModelInfo';
import axios from "axios";
import CustomButton from "components/CustomButtons/Button";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import { orange, green, } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  styleContainer: {
    display: "flex",
  },
  styleItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginRight: 8,
  },
  styleVersionVal: {
    display: "flex",
    alignItems: "flex-end	",
    justifyContent: "flex-start",
    fontWeight: "lighter",
  },
  btnSpacing: {
    marginLeft: 8,
  }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const SearchModal = ({
  modalOpen,
  setModalOpen,
  modalData,
  setModalData,
  modelNameData,
  modelVersionData,
  handleSearchBtn,
  setSelectOne,
}) => {
  const classes = useStyles();
  const [fileList, setFileList] = React.useState([]);
  const [modalDeleteCheck, setModalDeleteCheck] = React.useState(false);
  const [modalState, setModalState] = React.useState("");
  const [modalAlert, setModalAlert] = React.useState(false);

  React.useEffect(() => {
    getFileList();
  }, []);

  const getFileList = () => {
    axios({
      url: "/rest/1.0/list/files",
    })
      .then((res) => {
        let dataTmp = res.data;
        if (dataTmp.length > 0) {
          setFileList(
            dataTmp.map((data, idx) => {
              return { ...data, id: idx };
            })
          );
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  const handleDeleteBtn = () => {
    // manifest delete
    const formData = new FormData();
    formData.append("modelName", modelNameData);
    formData.append("modelVersion", modelVersionData);
    axios({
      url: `/rest/1.0/edit/manifest`,
      method: "DELETE",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((res) => {
        console.log("res: ", res);
        if (res.data.result) {
          if (res.data.result == 1) {
            console.log("manifest 삭제 성공");

            // get file_name
            let modelVersionTmp = modelVersionData.replace(".", "_");
            let fileName = `${modelNameData}-${modelVersionTmp}.zip`;
            console.log("fileName: ", fileName);

            //get file_id
            let fileId = "";
            if (fileList.length > 0) {
              fileList.map((file) => {
                if (file.file_name == fileName) {
                  fileId = file.file_id;
                }
              });
            }

            // file delete
            console.log("fileId: ", fileId);
            if (fileId !== "") {
              const formData2 = new FormData();
              formData2.append("file_id", fileId);
              axios({
                url: `/rest/1.0/edit/file`,
                method: "DELETE",
                headers: { "Content-Type": "multipart/form-data" },
                data: formData2,
              })
                .then((res) => {
                  console.log("res: ", res);
                  if (res.data.success) {
                    if (res.data.success) {
                      // alert("삭제가 완료됐습니다.");
                      setModalState("delete");
                      setModalAlert(true);
                      setModalDeleteCheck(false);
                      setModalOpen(false);
                      setSelectOne([]);
                      handleSearchBtn();
                    } else {
                      console.log("file 삭제 실패");
                    }
                  } else {
                    console.log("검색 결과가 없습니다.");
                    // alert("검색 결과가 없습니다.");
                  }
                })
                .catch((err) => {
                  console.log("err: ", err);
                });
            } else {
              console.log("fileId err");
            }

          } else {
            console.log("manifest 삭제 실패");
          }
        } else {
          console.log("검색 결과가 없습니다.");
          // alert("검색 결과가 없습니다.");
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        // alert("삭제에 실했습니다.");
        setModalState("deleteError");
        setModalAlert(true);
        setModalDeleteCheck(false);
        setModalOpen(false);
        setSelectOne([]);
      });
  };

  const handleSaveBtn = () => {
    console.log("modalData: ", modalData);
    axios({
      url: `/rest/1.0/edit/manifest`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: modalData,
    })
      .then((res) => {
        console.log("res: ", res);
        if (res.status === 200) {
          // alert("저장이 완료됐습니다.");
          setModalState("update");
          setModalAlert(true);
          setModalOpen(false);
          setSelectOne([]);
        } else {
          // alert("저장에 실패했습니다.");
          setModalState("updateError");
          setModalAlert(true);
          setModalOpen(false);
          setSelectOne([]);
          console.log("status: ", res.status);
        }
      })
      .catch((err) => {
        alert("err: ", err);
        console.log("err: ", err);
      });
  };

  const handleClose = (type) => {
    if (type === "errAlert") {
      setModalAlert(false);
      setSelectOne([]);
    } else if (type === "modal") {
      setModalOpen(false);
      setSelectOne([]);
    } else if (type === "delete") {
      setModalDeleteCheck(false);
    };
  };

  const modalAlertType = () => {
    if (modalState === "delete") {
      return (
        <>
          <CheckCircleOutlineOutlinedIcon style={{ color: green[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Deleted successfully.
          </DialogContentText>
        </>
      );
    } else if (modalState === "deleteError") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Delete failed.
          </DialogContentText>
        </>
      );
    } else if (modalState === "updateError") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Update failed.
          </DialogContentText>
        </>
      );
    } else if (modalState === "update") {
      return (
        <>
          <CheckCircleOutlineOutlinedIcon style={{ color: green[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Updated successfully.
          </DialogContentText>
        </>
      );
    } else if (modalState === "ListError") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Data does not exist.
          </DialogContentText>
        </>
      );
    }
  };

  return (
    <>
      <Dialog
        fullWidth
        open={modalOpen}
        onClose={() => { handleClose("modal") }}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => { handleClose("modal") }}
        >
          <div className={classes.styleContainer}>
            <div className={classes.styleItem}>
              {modelNameData}
            </div>
            <div className={classes.styleVersionVal}>
              {modelVersionData}
            </div>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <SearchingModelInfo
            modalData={modalData}
            setModalData={setModalData}
          />
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => { setModalDeleteCheck(true) }} color="google">
            delete
          </CustomButton>
          <Grid container display="flex" justify="flex-end" alignItems="center">
            <CustomButton className={classes.btnSpacing} onClick={handleSaveBtn} color="info">
              save
            </CustomButton>
            <CustomButton className={classes.btnSpacing} onClick={() => { handleClose("modal") }} color="tumblr">
              close
            </CustomButton>
          </Grid>
        </DialogActions>
      </Dialog>
      {/* alert dialog */}
      <Dialog
        open={modalDeleteCheck}
        onClose={() => { handleClose("delete") }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{``}</DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifycontent="flex-start" alignItems="center">
            <ErrorOutlineIcon style={{ color: green[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
            <DialogContentText id="alert-dialog-description">
              Do you want to delete the file?
            </DialogContentText>
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton variant="contained" onClick={handleDeleteBtn} color="info">
            ok
          </CustomButton>
          <CustomButton variant="contained" onClick={() => { handleClose("delete") }} color="tumblr">
            cancel
          </CustomButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalAlert}
        onClose={() => { handleClose("errAlert") }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifycontent="flex-start" alignItems="center">
            {modalAlertType()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => { handleClose("errAlert") }} variant="contained" color="info">
            OK
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchModal;