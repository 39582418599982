import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import React, { useState } from "react";
import FilePreviewer from "./Components/FilePreviewer";

function FileUploadMain() {
  const [csvFiles, setCsvFiles] = useState([]);

  return (
    <>
      <div className="content">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FilePreviewer
              csvFiles={csvFiles}
              setCsvFiles={setCsvFiles}
            />
          </GridItem>
        </GridContainer>
      </div>
    </>
  );
}

export default FileUploadMain;
