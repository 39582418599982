/* eslint-disable react/prop-types */
import React, { memo, useEffect, useRef, useState } from "react";
import { 
  Grid,
  CircularProgress, 
  Dialog, 
  DialogContent, 
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem";
import Papa from "papaparse";
import FileModelInfo from "../FileModelInfo";
import FileSubmit from "../FileSubmit";
import CsvEncoding from "./csvComponents/CsvEncoding";
import ImportCsvFile from "./csvComponents/ImportCsvFile";
import { defaultCsvData } from "./lib/defaultData";
import modalStyles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CustomButton from "components/CustomButtons/Button";
import { green, orange } from '@material-ui/core/colors';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';

const useModalStyle = makeStyles(modalStyles);
const useCardStyle = makeStyles(() => ({
  CardTitle: {
    padding: 0,
    marginTop: 0,
    marginBottom: 30,
  },
}));

const FilePreviewer = ({
  csvFiles,
  setCsvFiles,
}) => {
  const inputRef = useRef([]);
  const [dffIndex, setDffIndex] = useState([]);
  const [csvEncode, setCsvEncode] = useState({
    index: 0,
    value: "utf-8",
    label: "utf-8",
  });

  const [csvData, setCsvData] = useState(defaultCsvData);
  const [modelInfoLoad, setModelInfoLoad] = useState(false);
  const [otherFiles, setOtherFiles] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [modelNameData, setModelNameData] = useState();
  const [modelVersionData, setModelVersionData] = useState();
  const [nameVersionCheck, setNameVersionCheck] = React.useState(false);
  const [recentVersion, setRecentVersion] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalState, setModalState] = React.useState("");
  const [modalAlert, setModalAlert] = React.useState(false);
  const [missingKeys, setMissingKeys] = React.useState([]);

  const modalClasses = useModalStyle();
  const cardClasses = useCardStyle();

  React.useEffect(() => {
    // console.log("jsonData: ", jsonData);
    if (Object.keys(jsonData).includes("modelName")) {
      setModelNameData(jsonData["modelName"]);
    }
    if (Object.keys(jsonData).includes("modelVersion")) {
      setModelVersionData(jsonData["modelVersion"]);
    }
  }, [jsonData]);

  // React.useEffect(() => {
  //   console.log("name: ", modelNameData, ", version:", modelVersionData);
  // }, [modelNameData, modelVersionData]);

  // React.useEffect(() => {
  //   console.log("otherFiles:", otherFiles);
  // }, [otherFiles]);

  // useEffect(() => {
  //   setHideDataLoad(false);
  // }, []);

  // useEffect(() => {
  //   handleOnAddFile(csvFiles);
  // }, [csvEncode, csvFiles]);

  const handleOnCommit = () => {
    handleOnSetFiles({
      csv_encode: csvEncode.value,
      csv_files: csvFiles,
    });
    handleOnSetRules({
      csv_columns: csvData["columns"][0],
      origin_columns: csvData["origin_columns"][0],
    });

  };

  const handleOnAddFile = (_files) => {
    if (_files.length !== 0) {
      Papa.parse(_files[0].slice(0, 1024 * 1024 * 30), {
        encoding: csvEncode.value,
        complete: (results) => {
          const { data } = results;

          const rows = data.slice(1, data.length);

          const columns = data.slice(0, 1).map((v) =>
            v.map((val, idx) => {
              let data_type = "";

              for (let index = 0; index < rows.length; index++) {
                const value = rows[index];
                const index_value = value[idx] !== undefined ? value[idx] : -1;
                const index_value_isNaN = !isNaN(index_value);

                if (index_value_isNaN) {
                  data_type = "Float";
                } else {
                  data_type = "String";
                  break;
                }
              }

              return {
                index: idx,
                data_set: "field",
                data_type: data_type,
                value: val,
                data_format: "",
                data_func: [],
              };
            })
          );
          const columns2 = data.slice(0, 1).map((v) =>
            v.map((val, idx) => {
              let data_type = "";

              for (let index = 0; index < rows.length; index++) {
                const value = rows[index];
                const index_value = value[idx] !== undefined ? value[idx] : -1;
                const index_value_isNaN = !isNaN(index_value);

                if (index_value_isNaN) {
                  data_type = "Float";
                } else {
                  data_type = "String";
                  break;
                }
              }

              return {
                index: idx,
                data_set: "field",
                data_type: data_type,
                value: val,
                data_format: "",
                data_func: [],
              };
            })
          );

          const table = {
            columns: columns,
            origin_columns: columns2,
            rows: rows,
          };

          setCsvData(table);
          setCsvFiles(_files);
        },
        error: (error) => {
          console.log(error);
          setCsvFiles([]);
        },
      });
    }
  };

  const handleOnRemoveFile = () => {
    setCsvData(defaultCsvData);
    setCsvFiles([]);
    inputRef.current.map((inputs) => {
      inputs.value = "";
    });
  };

  // submit
  const handleSubmit = () => {
    setIsLoading(true);
    // console.log(modelNameData, ", ", modelVersionData);
    if (!modelNameData) {
      // alert("modelName을 입력하세요.");
      setModalState("modelName");
      setModalAlert(true);
      setIsLoading(false);
    } else if (!modelVersionData) {
      // alert("modelVersion을 입력하세요.");
      setModalState("modelVersion");
      setModalAlert(true);
      setIsLoading(false);
    } else {
      // version check
      let queryParam = `?modelName=${modelNameData}`;
      axios({
        url: `/rest/1.0/view/recent_version${queryParam}`,
      })
        .then((res) => {
          let dataTmp = res.data;
          if (dataTmp.length != 0) {
            setRecentVersion(dataTmp.recentVersion);
            let currentIntTmp;
            let currentMinTmp;
            let recentIntTmp;
            let recentMinTmp;
            if (modelVersionData.includes(".")) {
              currentIntTmp = parseFloat(modelVersionData.split(".")[0]);
              currentMinTmp = parseFloat(modelVersionData.split(".")[1]);
            }
            if (dataTmp.recentVersion.includes(".")) {
              recentIntTmp = parseFloat(dataTmp.recentVersion.split(".")[0]);
              recentMinTmp = parseFloat(dataTmp.recentVersion.split(".")[1]);
            }
            if (currentIntTmp <= recentIntTmp) {
              if (currentMinTmp <= recentMinTmp) {
                setIsLoading(false);
                setOpen(true);
              } else {
                filesUpload();
              }
            } else {
              filesUpload();
            }
          } else {
            filesUpload();
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("err0: ", err);
        });
    }
  };

  const filesUpload = () => {
    // files parameter
    const formData = new FormData();
    otherFiles.map((file) => {
      formData.append("files", file);
    });
    formData.append("modelName", modelNameData);
    formData.append("modelVersion", modelVersionData);

    axios({
      url: `/rest/1.0/edit/model_upload`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          axios({
            url: `/rest/1.0/edit/manifest`,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: jsonData,
          })
            .then((res) => {
              setIsLoading(false);
              if (res.status === 200) {
                // alert("업로드가 완료되었습니다.");
                setModalState("upload");
                setModalAlert(true);
                setCsvFiles([]);
                setJsonData([]);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              alert("err2: ", err);
              console.log("err2: ", err);
            });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err1: ", err);
        // alert("업로드에 실패했습니다.");
        setModalState("uploadError");
        setModalAlert(true);
      });
  };

  const handleClose = (type) => {
    if (type === "version") {
      setOpen(false);
    } else if (type === "upload") {
      setModalAlert(false);
    }
  };

  const handleAgree = () => {
    setIsLoading(true);
    setOpen(false);
    filesUpload();
  };

  const modalType = () => {
    if (modalState === "upload") {
      return (
        <>
          <CheckCircleOutlineOutlinedIcon style={{ color: green[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Upload successfully.
          </DialogContentText>
        </>
      );
    } else if (modalState === "uploadError") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Upload failed.
          </DialogContentText>
        </>
      );
    } else if (modalState === "modelName") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Enter modelName.
          </DialogContentText>
        </>
      );
    } else if (modalState === "modelVersion") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Enter modelVersion.
          </DialogContentText>
        </>
      );
    } else if (modalState === "jsonError") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            Corrupted json data.
          </DialogContentText>
        </>
      );
    } else if (modalState === "keysError") {
      return (
        <>
          <ErrorOutlineIcon style={{ color: orange[500], fontSize: 30, marginRight: 6, marginBottom: 10 }} />
          <DialogContentText id="alert-dialog-description">
            {missingKeys.length == 1 ? `Missing required key: ${missingKeys}` : `Missing required keys: ${missingKeys}`}
          </DialogContentText>
        </>
      );
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <h3 className={cardClasses.CardTitle}>UPLOADING</h3>
          <GridContainer>
            <GridItem xs={12} sm={4} md={3}>
              <CsvEncoding
                csvEncode={csvEncode}
                setCsvEncode={setCsvEncode}
                otherFiles={otherFiles}
              />
            </GridItem>
          </GridContainer>
          <ImportCsvFile
            handleOnAddFile={handleOnAddFile}
            csvFiles={csvFiles}
            setCsvFiles={setCsvFiles}
            handleOnRemoveFile={handleOnRemoveFile}
            inputRef={inputRef}
            csvEncode={csvEncode}
            dffIndex={dffIndex}
            setDffIndex={setDffIndex}
            modelInfoLoad={modelInfoLoad}
            setModelInfoLoad={setModelInfoLoad}
            setJsonData={setJsonData}
            setOtherFiles={setOtherFiles}
            setNameVersionCheck={setNameVersionCheck}
            setModelNameData={setModelNameData}
            setModelVersionData={setModelVersionData}
            setModalState={setModalState}
            setModalAlert={setModalAlert}
            setMissingKeys={setMissingKeys}
          />
        </CardBody>
      </Card>
      {modelInfoLoad ? (
        <>
          <Card>
            <CardBody>
              <h3 className={cardClasses.CardTitle}>FILE MODEL</h3>
              <FileModelInfo
                jsonData={jsonData}
                setJsonData={setJsonData}
                handleSubmit={handleSubmit}
                nameVersionCheck={nameVersionCheck}
              />
              <GridItem item xs={12} sm={12} md={12}>
              </GridItem>
              {nameVersionCheck ? (
                <FileSubmit
                  handleSubmit={handleSubmit}
                  jsonData={jsonData}
                  setJsonData={setJsonData}
                  modelNameData={modelNameData}
                  setModelNameData={setModelNameData}
                  modelVersionData={modelVersionData}
                  setModelVersionData={setModelVersionData}
                  nameVersionCheck={nameVersionCheck}
                />
              ) : null}
            </CardBody>
          </Card>
          {nameVersionCheck ? null : (
            <Card>
              <CardBody>
                <FileSubmit
                  handleSubmit={handleSubmit}
                  jsonData={jsonData}
                  setJsonData={setJsonData}
                  modelNameData={modelNameData}
                  setModelNameData={setModelNameData}
                  modelVersionData={modelVersionData}
                  setModelVersionData={setModelVersionData}
                  nameVersionCheck={nameVersionCheck}
                />
              </CardBody>
            </Card>
          )}
        </>
      ) : null}
      {/* manifest info */}
      <Dialog
        classes={{
          root: modalClasses.center + " " + modalClasses.modalRoot,
        }}
        open={isLoading}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
        fullWidth
        PaperProps={{
          style: { backgroundColor: "transparent", boxShadow: "none" },
        }}
      >
        <DialogContent
          id="classic-modal-slide-description"
          className={modalClasses.modalBody}
        >
          <CircularProgress />
        </DialogContent>
      </Dialog>
      {/* version check */}
      <Dialog
        open={open}
        fullWidth
        onClose={() => { handleClose("version") }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`recent version of ${modelNameData}: ${recentVersion}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please enter a version higher than <strong>{recentVersion}</strong>. Or do you want to overwrite version <strong>{modelVersionData}</strong>?
            {/* {recentVersion}보다 높은 버전을 입력해주세요. 아니면 {modelVersionData}버전에 덮어쓰시겠습니까? */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={handleAgree} color="info">
            YES
          </CustomButton>
          <CustomButton onClick={() => { handleClose("version") }} color="tumblr">
            NO
          </CustomButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={modalAlert}
        onClose={() => { handleClose("upload") }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <Grid container display="flex" justifycontent="flex-start" alignItems="center">
            {modalType()}
          </Grid>
        </DialogContent>
        <DialogActions>
          <CustomButton onClick={() => { handleClose("upload") }} variant="contained" color="info">
            OK
          </CustomButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default memo(FilePreviewer);
