import FileListMain from "views/FileList/FileListMain.js";
import FileUploadMain from "views/FileUpload/FileUploadMain.js";
import SearchingMain from "views/Searching/SearchingMain";
import LoginPage from "views/Pages/LoginPage.js";

// @material-ui/icons
import { Dashboard, PlaylistPlay, Image, Search, CloudUpload, FormatListBulleted, BackupOutlined } from "@material-ui/icons";

var dashRoutes = [
  {
    path: "/fileList",
    name: "File List",
    icon: FormatListBulleted,
    component: FileListMain,
    layout: "/admin",
  },
  {
    path: "/FileUpload",
    name: "Upload File",
    icon: BackupOutlined,
    component: FileUploadMain,
    layout: "/admin",
  },
  {
    path: "/Searching",
    name: "Search Manifest",
    icon: Search,
    component: SearchingMain,
    layout: "/admin",
  },
  {
    path: "/login-page",
    name: "Login Page",
    icon: Image,
    component: LoginPage,
    layout: "/auth",
  },
];
export default dashRoutes;
