import React, { useState } from "react";
import {
  Collapse,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchingTable from "./Components/SearchingTable";
import axios from "axios";
import SearchingSubmit from "./Components/SearchingSubmit";
import SearchingModal from "./Components/SearchingModal";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const useCardStyle = makeStyles(() => ({
  CardTitle: {
    padding: 0,
    marginTop: 0,
    marginBottom: 30,
  },
}));

const SearchingMain = () => {
  const cardClasses = useCardStyle();

  const [mFListLoad, setMFListLoad] = useState(false);
  const [mFList, setMFList] = useState([]);
  const [selectOne, setSelectOne] = useState([]);
  const [prevOne, setPrevOne] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modelNameData, setModelNameData] = useState("");
  const [modelVersionData, setModelVersionData] = useState("");
  const [modelName, setModelName] = useState();
  const [modelVersion, setModelVersion] = useState();
  const [modelTags, setModelTags] = useState();
  const [searchOption, setSearchOption] = useState("");
  const [modalData, setModalData] = useState({});
  const [noDataAlert, setNoDataAlert] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  // React.useEffect(() => {
  //   console.log("mFList: ", mFList);
  // }, [mFList]);

  // React.useEffect(() => {
  //   console.log("prevOne: ", prevOne);
  // }, [prevOne]);

  // React.useEffect(() => {
  //   console.log("selectOne: ", selectOne);
  // }, [selectOne]);

  React.useEffect(() => {
    if (Object.keys(modalData).length > 0) {
      // console.log("modalData: ", modalData);
      setModalOpen(true);
    }
  }, [modalData]);

  React.useEffect(() => {
    if (selectOne !== undefined && selectOne !== []) {
      // console.log("selectOne: ", selectOne);
      const model_name = mFList[selectOne]?.modelName;
      const model_version = mFList[selectOne]?.modelVersion;
      if (model_name !== undefined && model_version !== undefined) {
        setModelNameData(model_name);
        setModelVersionData(model_version);
        getManifestData(model_name, model_version);
      }
    }
  }, [selectOne, prevOne]);

  const getManifestData = (modelName, modelVersion) => {
    let queryParam = `?modelName=${modelName}&modelVersion=${modelVersion}`;
    axios({
      url: `/rest/1.0/view/manifest_search${queryParam}`,
    })
      .then((res) => {
        let dataTmp = res.data;
        // console.log("dataTmp: ", dataTmp);
        setModalData({ ...dataTmp });
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }

  // searching
  const handleSearchBtn = () => {
    setNoDataAlert(false);
    setCollapseOpen(false);
    setMFList([]);
    let queryParam;
    if (modelVersion && !searchOption) {
      setMFList([]);
      setMFListLoad(false);
      alert("modelVersion의 option을 선택해주세요.");
    } else {
      if (modelName && modelVersion && searchOption && modelTags) {
        queryParam = `?modelName=${modelName}&modelVersion=${modelVersion}&searchOption=${searchOption}&modelTags=${modelTags}`;
      } else if (modelName && modelVersion && searchOption !== "") {
        queryParam = `?modelName=${modelName}&modelVersion=${modelVersion}&searchOption=${searchOption}`;
      } else if (!modelName && modelVersion && searchOption !== "") {
        queryParam = `?modelVersion=${modelVersion}&searchOption=${searchOption}`;
      } else if (modelName && modelTags) {
        queryParam = `?modelName=${modelName}&modelTags=${modelTags}`;
      } else if (modelName) {
        queryParam = `?modelName=${modelName}`;
      } else if (modelTags) {
        queryParam = `?modelTags=${modelTags}`;
      } else {
        queryParam = "";
      }
      // console.log("queryParam: ", queryParam);
      axios({
        url: `/rest/1.0/list/multi_key_value_search${queryParam}`,
      })
        .then((res) => {
          let dataTmp = res.data;
          let result = [];
          if (typeof dataTmp == "string") {
          } else {
            if (dataTmp.length > 0) {
              setMFListLoad(true);
              result = dataTmp.map((data, idx) => {
                if (Object.keys(data).includes("trainDataInfo") && Object.keys(data.trainDataInfo).includes("targetName")) {
                  return ({ ...data, id: idx, targetName: data.trainDataInfo.targetName });
                } else {
                  return ({ ...data, id: idx });
                }
              });
              setMFList([...result]);
            } else {
              // alert("검색된 데이터가 없습니다.");
              setNoDataAlert(true);
              setCollapseOpen(true);
              setMFListLoad(false);
            }
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <h3 className={cardClasses.CardTitle}>SEARCHING</h3>
          <SearchingSubmit
            handleSearchBtn={handleSearchBtn}
            setModelName={setModelName}
            setModelVersion={setModelVersion}
            setModelTags={setModelTags}
            searchOption={searchOption}
            setSearchOption={setSearchOption}
          />
        </CardBody>
      </Card>
      {mFListLoad ? (
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                <SearchingTable
                  mFList={mFList}
                  selectOne={selectOne}
                  setSelectOne={setSelectOne}
                  prevOne={prevOne}
                  setPrevOne={setPrevOne}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      ) : null}
      <SearchingModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalData={modalData}
        setModalData={setModalData}
        modelNameData={modelNameData}
        modelVersionData={modelVersionData}
        handleSearchBtn={handleSearchBtn}
        setSelectOne={setSelectOne}
      />
      {noDataAlert ? (
        <Card>
          <CardBody>
            <Collapse in={collapseOpen}>
              <Alert severity="info">
                <AlertTitle>Info</AlertTitle>
                There is no data. — <strong>check it out!</strong>
              </Alert>
            </Collapse>
          </CardBody>
        </Card>
      ) : null}
    </>
  );
};

export default SearchingMain;