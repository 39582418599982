import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { TextField, Grid, Button } from "@material-ui/core"; // Button
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { cyan } from "@material-ui/core/colors";
import oc from "open-color";
import styled from "styled-components";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: cyan["100"],
      main: cyan["500"],
      dark: cyan["600"],
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
  rootDiv: {
    flexGrow: 1,
  },
  itemStyle: {
    marginBottom: "15px"
  },
  styleTitle: {
    fontSize: 30,
    marginTop: 20,
    marginBottom: 40,
  },
  styleKeys: {
    marginLeft: "6px",
    marginRight: "6px",
    height: "40px",
    fontSize: 12,
    whiteSpace: "nowrap",
  },
  styleLine1: {
    width: "10px",
    marginTop: "10px",
    height: "10px",
    borderTop: "1px solid #bdbdbd",
  },
  styleLine2: {
    width: "100%",
    marginTop: "0px",
    height: "10px",
    borderTop: "1px solid #bdbdbd",
  },
  styleDeps: {
    marginLeft: 50,
  },
  styleContainer: {
    display: "flex",
  },
  styleSubmit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  test: {
    fontSize: "40px",
  },
}));

const FileButton = styled.button`
  background: none;
  backgroundcolor: ${oc.gray[8]};
  border: 1px solid ${oc.gray[6]};
  color: ${oc.gray[8]};
  padding: 8px 20px;
  font-weight: bold;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  :disabled {
    cursor: default;
    color: ${oc.gray[5]};
  }
  :hover:enabled {
    background: ${oc.gray[6]};
    color: white;
  }
  :active:enabled {
    background: ${oc.gray[6]};
    color: white;
  }
  & + & {
    margin-left: 0.5rem;
  }
`;

const FileSubmit = ({
  handleSubmit,
  jsonData,
  setJsonData,
  modelNameData,
  modelVersionData,
  nameVersionCheck,
}) => {
  const classes = useStyles();

  const handleInputchange = (e) => {
    const name = e.target.name;
    if (name === "name") {
      setJsonData({ ...jsonData, ["modelName"]: e.target.value });
    } else if (name === "version") {
      setJsonData({ ...jsonData, ["modelVersion"]: e.target.value });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <GridContainer>
        <GridItem className={classes.itemStyle} item xs={12} sm={10} md={10}>
          <Grid container display="flex" justifycontent="center" alignItems="center" spacing={2}>
            {nameVersionCheck ? null : (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={modelNameData || ""}
                    id="outlined-size-small"
                    label="modelName"
                    variant="outlined"
                    size="small"
                    name="name"
                    onChange={handleInputchange}
                  ></TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    value={modelVersionData || ""}
                    id="outlined-size-small"
                    label="modelVersion"
                    variant="outlined"
                    size="small"
                    name="version"
                    onChange={handleInputchange}
                  ></TextField>
                </Grid>
              </>
            )}
          </Grid>
        </GridItem>
        <GridItem item xs={12} sm={2} md={2}>
          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>upload</Button>
        </GridItem>
      </GridContainer>
    </MuiThemeProvider>
  );
};

export default FileSubmit;
