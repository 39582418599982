import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import {
  TextField,
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { cyan } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: cyan["100"],
      main: cyan["500"],
      dark: cyan["600"],
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(1),
    },
  },
  rootDiv: {
    flexGrow: 1,
  },
  itemStyle: {
    marginBottom: "15px"
  },
  formControl: {
    minWidth: "100%",
  },
}));

const SearchingSubmit = ({
  handleSearchBtn,
  searchKey,
  searchValue,
  setModelName,
  setModelVersion,
  setModelTags,
  searchOption,
  setSearchOption
}) => {
  const classes = useStyles();

  const handleInputchange = (e) => {
    const name = e.target.name;
    if (name === "name") {
      setModelName(e.target.value);
    } else if (name === "version") {
      setModelVersion(e.target.value);
    } else if (name === "tag") {
      setModelTags(e.target.value);
    }
  };

  const handleOptionChange = (event) => {
    setSearchOption(event.target.value);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <GridContainer>
        <GridItem className={classes.itemStyle} xs={12} sm={10} md={10}>
          <Grid container display="flex" justifycontent="center" alignItems="center" spacing={2}>
            <Grid item xs={12} sm={3} md={4} style={{ paddingBottom: 0 }}>
              <TextField
                fullWidth
                value={searchKey}
                id="outlined-size-small"
                label="modelName"
                variant="outlined"
                size="small"
                name="name"
                onChange={handleInputchange}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} style={{ paddingBottom: 0 }}>
              <Grid container display="flex" justifycontent="center" alignItems="center" spacing={1}>
                <Grid item xs={4} sm={4} md={4}>
                  <FormControl variant="outlined" size="small" className={classes.formControl}>
                    <InputLabel id="simple-select-outlined">option</InputLabel>
                    <Select
                      labelId="simple-select-outlined"
                      id="simple-select-outlined"
                      value={searchOption}
                      onChange={handleOptionChange}
                      label="searchOption"
                    >
                      <MenuItem value={"equal"}>{`==`}</MenuItem>
                      <MenuItem value={"upper"}>{`>=`}</MenuItem>
                      <MenuItem value={"lower"}>{`<=`}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={8} sm={8} md={8}>
                  <TextField
                    fullWidth
                    value={searchValue}
                    id="outlined-size-small"
                    label="modelVersion"
                    variant="outlined"
                    size="small"
                    name="version"
                    onChange={handleInputchange}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} md={4} style={{ paddingBottom: 0 }}>
              <TextField
                fullWidth
                value={searchValue}
                id="outlined-size-small"
                label="modelTags"
                variant="outlined"
                size="small"
                name="tag"
                onChange={handleInputchange}
              ></TextField>
            </Grid>
          </Grid>
        </GridItem>
        <GridItem xs={12} sm={2} md={2} style={{ paddingBottom: 0 }}>
          <Button
            fullWidth
            minWidth="100px"
            // style={{ width: "150px" }}
            variant="contained"
            color="primary"
            onClick={handleSearchBtn}
          >
            search
          </Button>
        </GridItem>
      </GridContainer>
    </MuiThemeProvider>
  );
};

export default SearchingSubmit;