/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import { DataGrid } from "@material-ui/data-grid";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { cyan, blue } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: cyan["100"],
      main: cyan["600"],
      dark: cyan["900"],
      contrastText: '#fff',
    },
    secondary: {
      light: blue["100"],
      main: blue["700"],
      dark: blue["900"],
      contrastText: '#000',
    },
  },
});

const columns = [
  {
    field: "id",
    headerName: "id",
    sortable: false,
    editable: false,
    width: 90,
    hide: true,
  },
  {
    field: "modelName",
    headerName: "modelName",
    sortable: false,
    editable: false,
    width: 150,
  },
  {
    field: "modelVersion",
    headerName: "modelVersion",
    sortable: false,
    editable: false,
    width: 150,
  },
  {
    field: "modelTags",
    headerName: "modelTags",
    sortable: false,
    editable: false,
    width: 250,
  },
  {
    field: "modelPurpose",
    headerName: "modelPurpose",
    sortable: false,
    editable: false,
    width: 200,
  },
  {
    field: "targetName",
    headerName: "targetName",
    sortable: false,
    editable: false,
    width: 200,
  },
];

const SearchingTable = ({ mFList, selectOne, setSelectOne, prevOne, setPrevOne }) => {
  const checkRef = useRef();

  const handleSelectOne = (checkedList) => {
    // console.log("checkedList: ", checkedList);
    if (checkedList.length === 0) {
      setPrevOne(true);
      setSelectOne([]);
    } else if (checkedList.length === 1) {
      setPrevOne(checkedList[0]);
      setSelectOne(checkedList[0]);
    } else if (checkedList.length > 1) {
      checkedList.map((id) => {
        if(id !== prevOne) {
          setPrevOne(id);
          setSelectOne(id);
        }
      });
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div style={{ width: "100%" }}>
        <DataGrid 
          ref={checkRef}
          className="dataGrid"
          autoHeight
          rows={mFList} 
          columns={columns} 
          checkboxSelection
          onSelectionModelChange={handleSelectOne}
          selectionModel={selectOne}
        />
      </div>
    </MuiThemeProvider>
  );
};

export default SearchingTable;
